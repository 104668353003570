import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import imgFeatureList1 from '../../images/public/users.png'
import imgFeatureList2 from '../../images/public/star.png'
import imgFeatureList3 from '../../images/public/badge.png'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import BlogListNew from '../partials/BlogListNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import CallToActRegister from '../partials/CallToActRegister.jsx'


import {getOSInfo , translate } from '../../helpers/global'

import imgTech1 from '../../images/tech/tech-nextgen-1.png'
import imgTech2 from '../../images/tech/tech-nextgen-2.png'
import imgTech3 from '../../images/tech/tech-nextgen-3.png'
import imgTech4 from '../../images/tech/tech-nextgen-4.png'

import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

class TechNextgen extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

  render() {
    const {
      globalGetLanguages,
      user
    } = this.props
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
			<div id="homepage">
        <HeaderNewClear isOnStatusFor="is-on" />
        <div className="section-top-solution relative double-triangle triangle-left" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='solution-top-box-hanging aqua'>
                  <div className="for-tag">NextGen Assessment Center</div>
                  <h1 className='m-t-0 m-b-16'>
                  Elevate Your Assessment Center To The Next Level
                  </h1>
                  <p className='m-b-0'>Deliver better performance to your existing assessment center or empower your assessors with NextGen Assessment Center technology driving you forward.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right flex-container flex-end align-items-center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgTech1} />
              </div>
            </div>
          </section>
          <div className='solution-back-top aqua desktop-hide' style={globalGetLanguages == 'en' ? {height: '579px'} : {height: '579px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech2} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='red-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                  Reduce Data In-Take<br/>by More Than 50%
                  </h1>
                  <p className='m-b-0'>
                  NextGen Assessment Center uses asynchronous technology to remove offline assessments, simplifying data input and quickening the entire assessment process.
                  </p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='red-back-feature-right for-2 desktop-hide' style={globalGetLanguages == 'en' ? {height: '455.6px'} : {height: '455.6px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-left disable-small-triangle sol-sec2-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging'>
                  <h1 className='m-t-0 m-b-16 tech'>
                  Empower Your Assessors To Be Precise, Detailed & Flexible
                  </h1>
                  <p className='m-b-0'>NextGen Assessment Center establishes a controlled, inclusive, and objective environment designed to minimize bias and external variables, enabling assessors to obtain more precise and focused outcomes.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech3} />
              </div>
            </div>
          </section>
          <div className='navy-back-feature-left for-1 desktop-hide' style={globalGetLanguages == 'en' ? {height: '599.8px'} : {height: '599.8px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech4} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='purple-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                  Faster Results & Detailed Reporting
                  </h1>
                  <p className='m-b-0'>NextGen Assessment Center's async assessments has proven to reduce participant time by 63%, while at the same time increase confidence and delivering better answers. This increases accuracy especially in mapping skills and potentials.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='purple-back-feature-right for-2 desktop-hide' style={globalGetLanguages == 'en' ? {height: '483.8px'} : {height: '483.8px'}}></div>
        </div>
        <FooterNew />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TechNextgen)

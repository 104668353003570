import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import imgFeatureList1 from '../../images/public/users.png'
import imgFeatureList2 from '../../images/public/star.png'
import imgFeatureList3 from '../../images/public/badge.png'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import BlogListNew from '../partials/BlogListNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import CallToActRegister from '../partials/CallToActRegister.jsx'


import {getOSInfo , translate } from '../../helpers/global'

import imgTech1 from '../../images/tech/tech-structured-1.png'
import imgTech2 from '../../images/tech/tech-structured-2.png'
import imgTech3 from '../../images/tech/tech-structured-3.png'
import imgTech4 from '../../images/tech/tech-structured-4.png'

import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

class TechStructured extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

  render() {
    const {
      globalGetLanguages,
      user
    } = this.props
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
			<div id="homepage">
        <HeaderNewClear isOnStatusFor="is-on" />
        <div className="section-top-solution relative double-triangle triangle-left" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='solution-top-box-hanging aqua'>
                  <div className="for-tag">Structured Interview Builder</div>
                  <h1 className='m-t-0 m-b-16'>
                  Craft Structured Interviews Instantly
                  </h1>
                  <p className='m-b-0'>ASTRNT’s Structured Builder is a unique interview automation tool that provides job-related competencies, structured questions, templates, and assessment questions for any role, enabling hiring teams to build effective interviews quickly.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right flex-container flex-end align-items-center p-l-45-desk'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgTech1} />
              </div>
            </div>
          </section>
          <div className='solution-back-top aqua desktop-hide' style={globalGetLanguages == 'en' ? {height: '534px'} : {height: '534px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center p-r-45-desk'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech2} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='red-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                  Ensure Faster, Consistent Hiring with Structured Interviews
                  </h1>
                  <p className='m-b-0'>
                  Utilize your own competencies, questions, and assessments, or choose from our available library. Accelerate time-to-interview and evaluation with our templates included in the structured interview builder.
                  </p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='red-back-feature-right for-2 desktop-hide' style={globalGetLanguages == 'en' ? {height: '555.8px'} : {height: '555.8px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-left disable-small-triangle sol-sec2-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging'>
                  <h1 className='m-t-0 m-b-16 tech'>
                  No More<br/>Searching Online for Interview Questions
                  </h1>
                  <p className='m-b-0'>Provide your hiring teams with validated assessments. Achieve fairer hiring outcomes with optimized questions and structured interviewing, all integrated into our software.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center p-l-45-desk'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech3} />
              </div>
            </div>
          </section>
          <div className='navy-back-feature-left for-1 desktop-hide' style={globalGetLanguages == 'en' ? {height: '500px'} : {height: '500px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech4} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='purple-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                  Ensure Consistency within Your Hiring Team
                  </h1>
                  <p className='m-b-0'>Evaluate candidates uniformly using integrated evaluation guides. Hiring teams can quickly align using job-related attributes and behavior examples validated by our in-house People Data and Data Innovation Team.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='purple-back-feature-right for-2 desktop-hide' style={globalGetLanguages == 'en' ? {height: '555.8px'} : {height: '555.8px'}}></div>
        </div>
        <FooterNew />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TechStructured)

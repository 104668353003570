import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import imgFeatureList1 from '../../images/public/users.png'
import imgFeatureList2 from '../../images/public/star.png'
import imgFeatureList3 from '../../images/public/badge.png'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import BlogListNew from '../partials/BlogListNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import CallToActRegister from '../partials/CallToActRegister.jsx'


import {getOSInfo , translate } from '../../helpers/global'

import imgTech1 from '../../images/tech/tech-holistic-1.png'
import imgTech2 from '../../images/tech/tech-holistic-2.png'
import imgTech3 from '../../images/tech/tech-holistic-3.png'

import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

class TechHolistic extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

  render() {
    const {
      globalGetLanguages,
      user
    } = this.props
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
			<div id="homepage">
        <HeaderNewClear isOnStatusFor="is-on" />
        <div className="section-top-solution relative double-triangle triangle-left" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='solution-top-box-hanging aqua'>
                  <div className="for-tag">Holistic Assessments</div>
                  <h1 className='m-t-0 m-b-16'>
                  See Beyond Skills:<br/>Holistic Assessments
                  </h1>
                  <p className='m-b-0'>ASTRNT is a thorough, all-encompassing assessment technology that provides you with a complete picture of every applicant. Pre-built questions and data-driven insights help you attract top talent and make more informed recruiting decisions while delivering a smooth and engaging hiring process.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right flex-container flex-end align-items-center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgTech1} />
              </div>
            </div>
          </section>
          <div className='solution-back-top aqua desktop-hide' style={globalGetLanguages == 'en' ? {height: '561px'} : {height: '561px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech2} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='red-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                  Simplify Mass Hiring Without<br/>Compromising Quality
                  </h1>
                  <p className='m-b-0'>
                  When scaling recruitment, maintaining quality is crucial. Our holistic assessment streamline the process, allowing you to handle large volumes of candidates efficiently. Customizable assessments ensure each candidate is evaluated based on your specific role requirements and company culture, so you always find the right fit.
                  </p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='red-back-feature-right for-2 desktop-hide' style={globalGetLanguages == 'en' ? {height: '609.5px'} : {height: '609.5px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-left disable-small-triangle sol-sec2-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging'>
                  <h1 className='m-t-0 m-b-16 tech'>
                  Future-Proof Your Talent for Long-Term Success
                  </h1>
                  <p className='m-b-0'>Ensure your company’s success by identifying and developing top talent. Use talent mapping and skills gap analysis to understand future needs and guide employee growth. Personalized learning paths equip your team to excel in key roles and achieve their goals.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech3} />
              </div>
            </div>
          </section>
          <div className='navy-back-feature-left for-1 desktop-hide' style={globalGetLanguages == 'en' ? {height: '554px'} : {height: '554px'}}></div>
        </div>
        <FooterNew />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TechHolistic)

// @flow

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import $ from 'jquery'
import ModalEnterCode from './ModalEnterCode'
import imgAppStore from '../../images/astronaut/appstore.png'
import imgGooglePlay from '../../images/astronaut/gplay.png'
import initializers from '../../dependencies/initializers'
import bindAll from 'lodash/bindAll'
import {getOSInfo , translate } from '../../helpers/global'
import AstrntLogoWhite from '../../styles/img/astro-logo-white.svg?v=1'
import appleButton from '../../styles/img/mobile-app/apple.jpg'
import googleButton from '../../styles/img/mobile-app/google.jpg'
import imgIsoLogo from '../../images/iso-logo.png'

type Props = {};

class FooterNew extends React.Component<Props> {


	constructor(props) {
		super(props)
		this.state = {
		angleClass: 'fa-angle-down',
		langHide:'hide',
		langHideMobile:'hide',
		};
		this.footerLangContainerRef = React.createRef();
		this.footerLangContainerRefMobile = React.createRef();

		bindAll(this, [
			'handleDropDeadClick',
			'handleDropDeadClickMobile',
			'detectClick',
		])
	}


	chooseOpt(val) {
		const url_api = initializers.api_host
		  if(val === '1') {
			  if (process.env.NODE_ENV === 'development') {
				window.location = 'http://localhost:3000/'
			} else if (url_api == 'https://beta.astrnt.co') {
	
				window.location = 'https://test.astrnt.co/'
	
			} else {
				window.location = 'https://astrnt.co/'
			}
		  } else {
			  if (process.env.NODE_ENV === 'development') {
				window.location = 'http://localhost:3000/'
			} else if (url_api == 'https://beta.astrnt.co') {
	
				window.location = 'https://test.astrnt.id/'
	
			} else {
				window.location = 'https://astrnt.id/'
			}
		  }
		}

	handleDropDeadClick(e){
		if (this.state.langHide == 'hide') {
			this.setState({
				angleClass: 'fa-angle-up',
				langHide:'',
			})
		}else{
			this.setState({
				angleClass: 'fa-angle-down',
				langHide:'hide',
			})
		}
	}

	handleDropDeadClickMobile(e){
		if (this.state.langHideMobile == 'hide') {
			this.setState({
				angleClass: 'fa-angle-up',
				langHideMobile:'',
			})
		}else{
			this.setState({
				angleClass: 'fa-angle-down',
				langHideMobile:'hide',
			})
		}
	}

  handleEnterCodeClick(e: Object) {
  	e.preventDefault()

  	$('#modalEnterCode').modal('show')
  }

  componentDidMount()
  {
    $('ul.footer-dropdown li a.dropdown-footer').click(function(event){
        event.preventDefault();
        
        if(!$(this).hasClass('is-on')){
            $(this).addClass('is-on').parent().find('div').slideDown('fast');
        }else{
            $(this).removeClass('is-on').parent().find('div').slideUp('fast');
        }
    });

	window.addEventListener("click", this.detectClick)

  }

  detectClick(event){
	if (this.footerLangContainerRef.current != null) {
		if (!this.footerLangContainerRef.current.contains(event.target)) {
			this.setState({
				angleClass: 'fa-angle-down',
				langHide:'hide',
			})
		}
	}
	if (this.footerLangContainerRefMobile.current != null) {
		if (!this.footerLangContainerRefMobile.current.contains(event.target)) {
			this.setState({
				angleClass: 'fa-angle-down',
				langHideMobile:'hide',
			})
		}
	}
}


  render() {

	const {
		globalGetLanguages,
	} = this.props

    return(
    	<Fragment>
				<footer className="desktop-hide">
	          <section>
	            <ul>
	            	<li id="footer-first-li">
	            	  <img src={AstrntLogoWhite} />
	            	  {translate(globalGetLanguages,'FOOTER_AUTOMATION')}
	            	  <ul className="for-soc">
	                  <li>
	                    <a href="https://www.facebook.com/astrnt.co" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook" /></a>
	                  </li><li>
	                    <a href="https://www.youtube.com/c/astrntco" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube" /></a>
	                  </li><li>
	                    <a href="https://www.linkedin.com/company/astrnt-co" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin" /></a>
	                  </li><li>
	                    <a href="https://www.instagram.com/astrnt.co/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
	                  </li>
	                </ul>
	            	</li>
	              <li>
				  {translate(globalGetLanguages,'SOLUTIONS')}
	                <ul>
	                  <li><Link to={`/solution/talent-acquisition`}>{translate(globalGetLanguages,'TALENT_ACQUISITION')}</Link></li>
	                  <li><Link to={`/solution/talent-management`}>{translate(globalGetLanguages,'TALENT_MANAGEMENT')}</Link></li>
	                  <li><Link to={`/solution/university-admission`}>{translate(globalGetLanguages,'UNIVERSITY_ADMISSION')}</Link></li>
	                  <li><Link to={`/solution/powered-by-astrnt`}>{translate(globalGetLanguages,'POWERED_BY_ASTRNT')}</Link></li>
	                  {/* <li><Link to={`/astronaut-for/blue-collar-recruitment`}>{translate(globalGetLanguages,'FOOTER_BLUE_COLLAR')}</Link></li>
	                  <li><Link to={`/astronaut-for/assessment-certification`}>{translate(globalGetLanguages,'FOOTER_ASSESMENT_CERT')}</Link></li>
	                  <li><Link to={`/astronaut-for/video-platform`}>{translate(globalGetLanguages,'FOOTER_TALENT_PLATFORM')}</Link></li> */}
	                </ul>
	              </li>
	              <li>
				  {translate(globalGetLanguages,'RESOURCES')}
	                <ul>
	                  <li className='hide'><Link to={`/plans`}>Our Tech</Link></li>
	                  <li><Link to={`/plans`}>{translate(globalGetLanguages,'PLANS')}</Link></li>
	                  <li><Link to={`/how-it-works`}>{translate(globalGetLanguages,'FOOTER_FAQ')}</Link></li>
	                  <li><a href={`${initializers.dev_host}/api-documentation`} target="_blank" rel="noopener noreferrer">{translate(globalGetLanguages,'FOOTER_API_DOC')}</a></li>
	                  <li><Link to={`/termsofservice`}>{translate(globalGetLanguages,'FOOTER_TOS')}</Link></li>
	                  <li><Link to={`/privacypolicy`}>{translate(globalGetLanguages,'FOOTER_PRIVACY')}</Link></li>
										
	                </ul>
	              </li>
	              <li>
				  {translate(globalGetLanguages,'COMPANY')}
	                <ul>
	                  <li><Link to={`/about`}>{translate(globalGetLanguages,'ABOUT')}</Link></li>
	                  <li><Link to={`/partnership`}>{translate(globalGetLanguages,'PARTNERS')}</Link></li>
										<li><a href={initializers.blog_host} target="_blank" rel="noopener noreferrer">{translate(globalGetLanguages,'FOOTER_LIFE_BLOG')}</a></li>
	                  <li><a href="mailto:help@astrnt.co" target="_blank" rel="noopener noreferrer">{translate(globalGetLanguages,'CONTACT')}</a></li>
	                </ul>
	              </li>
	              <li>
				  {translate(globalGetLanguages,'CANDIDATES')}
	                <ul>
	                  {/*<li><Link to={`/enter-code`}>Enter Code</Link></li>*/}
	                  <li><Link to={'/candidate-support'}>{translate(globalGetLanguages,'FOOTER_CANDIDATE_SUPPORT')}</Link></li>
	                </ul>
	                <img src={imgIsoLogo} />
	              </li>
	            </ul>
	          </section>
	          <div className="the-copy">
	          	<section ref={this.footerLangContainerRef}>
				  {translate(globalGetLanguages,'FOOTER_COPYRIGHT')}
	            	<a onClick={(e)=>{this.handleDropDeadClick(e)}} style={{position: 'relative'}}>
	            		<i className="fas fa-globe"></i> {translate(globalGetLanguages,'FOOTER_LANG')} <i className={`fas ${this.state.angleClass}`}></i>
	            		<div className={`dropdown-lang-footer ${this.state.langHide}`}>
		                	  <div onClick={() => this.chooseOpt('1')}>English</div>
		                	  <div onClick={() => this.chooseOpt('2')}>Indonesia</div>
						</div>
	            	</a>
	            </section>
	          </div>
        </footer>
        <footer className="mobile-hide">
	          <section>
	            <ul className="footer-dropdown">
	            	<li>
	            	  <img src={AstrntLogoWhite} />
					  {translate(globalGetLanguages,'FOOTER_AUTOMATION')}
	            	  <ul className="for-soc">
	                  <li>
	                    <a href="https://www.facebook.com/astrnt.co" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook" /></a>
	                  </li><li>
	                    <a href="https://www.youtube.com/c/astrntco" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube" /></a>
	                  </li><li>
	                    <a href="https://www.linkedin.com/company/astrnt-co" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin" /></a>
	                  </li><li>
	                    <a href="https://www.instagram.com/astrnt.co/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
	                  </li>
	                </ul>
	            	</li>
	              <li>
	              	<a className="dropdown-footer" href="#">
					  {translate(globalGetLanguages,'SOLUTIONS')}
	              	  <i className="fa fa-angle-down" />
	              	</a>
	              	<div style={{display: 'none'}}>
		                <ul>
							<li><Link to={`/solution/talent-acquisition`}>{translate(globalGetLanguages,'TALENT_ACQUISITION')}</Link></li>
							<li><Link to={`/solution/talent-management`}>{translate(globalGetLanguages,'TALENT_MANAGEMENT')}</Link></li>
							<li><Link to={`/solution/university-admission`}>{translate(globalGetLanguages,'UNIVERSITY_ADMISSION')}</Link></li>
							<li><Link to={`/solution/powered-by-astrnt`}>{translate(globalGetLanguages,'POWERED_BY_ASTRNT')}</Link></li>
		                </ul>
	               	</div>
	              </li>
	              <li>
	              	<a className="dropdown-footer" href="#">
					  Resources
	              	  <i className="fa fa-angle-down" />
	              	</a>
	              	<div style={{display: 'none'}}>
		                <ul>
							<li className='hide'><Link to={`/plans`}>Our Tech</Link></li>
							<li><Link to={`/plans`}>{translate(globalGetLanguages,'PRICING')}</Link></li>
							<li><Link to={`/how-it-works`}>{translate(globalGetLanguages,'FOOTER_FAQ')}</Link></li>
							<li><a href={`${initializers.dev_host}/api-documentation`} target="_blank" rel="noopener noreferrer">{translate(globalGetLanguages,'FOOTER_API_DOC')}</a></li>
							<li><Link to={`/termsofservice`}>{translate(globalGetLanguages,'FOOTER_TOS')}</Link></li>
							<li><Link to={`/privacypolicy`}>{translate(globalGetLanguages,'FOOTER_PRIVACY')}</Link></li>
	                	</ul>
	                </div>
	              </li>
	              <li>
	              	<a className="dropdown-footer" href="#">
					  {translate(globalGetLanguages,'COMPANY')}
	              	  <i className="fa fa-angle-down" />
	              	</a>
	              	<div style={{display: 'none'}}>
		                <ul>
							<li><Link to={`/about`}>{translate(globalGetLanguages,'ABOUT')}</Link></li>
							<li><Link to={`/partnership`}>{translate(globalGetLanguages,'PARTNERS')}</Link></li>
							<li><a href={initializers.blog_host} target="_blank" rel="noopener noreferrer">{translate(globalGetLanguages,'FOOTER_LIFE_BLOG')}</a></li>
							<li><a href="mailto:help@astrnt.co" target="_blank" rel="noopener noreferrer">{translate(globalGetLanguages,'CONTACT')}</a></li>
		                </ul>
		              </div>
	              </li>
	              <li>
				  {translate(globalGetLanguages,'CANDIDATES')}
	                <ul>
	                  {/*<li><Link to={`/enter-code`}>Enter Code</Link></li>*/}
	                  <li><Link to={'/candidate-support'}>{translate(globalGetLanguages,'FOOTER_CANDIDATE_SUPPORT')}</Link></li>
	                </ul>
	                <img src={imgIsoLogo} />
	              </li>
	            </ul>
	          </section>
	          <div className="the-copy">
	          	<section ref={this.footerLangContainerRefMobile}>
	          		<a onClick={(e)=>{this.handleDropDeadClickMobile(e)}} style={{position: 'relative'}}>
	            		<i className="fas fa-globe"></i> {translate(globalGetLanguages,'FOOTER_LANG')} <i className={`fas ${this.state.angleClass}`}></i>
	            		<div className={`dropdown-lang-footer ${this.state.langHideMobile}`}>
		                	  <div onClick={() => this.chooseOpt('1')}>English</div>
		                	  <div onClick={() => this.chooseOpt('2')}>Indonesia</div>
						</div>
	            	</a>
					  {translate(globalGetLanguages,'FOOTER_COPYRIGHT')}
					  {/* <div className="dropdown-lang">
		                	  <div onClick={() => this.chooseOpt('2')}>English</div>
		                	  <div onClick={() => this.chooseOpt('2')}>Indonesia</div>
						</div> */}
	            </section>
	          </div>
        </footer>
      </Fragment>
		)
  }
}

const mapStateToProps = (state) => {
	return{ 
	  globalGetLanguages:state.globalGetLanguages,
	}
	
  }

export default connect(mapStateToProps, null )(FooterNew)


import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import imgFeatureList1 from '../../images/public/users.png'
import imgFeatureList2 from '../../images/public/star.png'
import imgFeatureList3 from '../../images/public/badge.png'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import BlogListNew from '../partials/BlogListNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import CallToActRegister from '../partials/CallToActRegister.jsx'


import {getOSInfo , translate } from '../../helpers/global'

import imgTech1 from '../../images/tech/tech-video-1.png'
import imgTech2 from '../../images/tech/tech-video-2.png'
import imgTech3 from '../../images/tech/tech-video-3.png'
import imgTech4 from '../../images/tech/tech-video-4.png'
import imgTech5 from '../../images/tech/tech-video-5.png'

import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

class TechVideo extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

  render() {
    const {
      globalGetLanguages,
      user
    } = this.props
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
			<div id="homepage">
        <HeaderNewClear isOnStatusFor="is-on" />
        <div className="section-top-solution relative double-triangle triangle-left" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='solution-top-box-hanging aqua'>
                  <div className="for-tag">Video Interviewing</div>
                  <h1 className='m-t-0 m-b-16'>
                    Enable Candidates<br/>
                    to Share Their Experiences
                  </h1>
                  <p className='m-b-0'>Our video interview platform, with on-demand, streamlines hiring with real-time assessments, interview guides, automated sorting, and shareable recordings. At the same time, it delivers
                  an exceptional, modern experience for candidates.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right flex-container flex-end align-items-center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgTech1} />
              </div>
            </div>
          </section>
          <div className='solution-back-top aqua desktop-hide' style={globalGetLanguages == 'en' ? {height: '606px'} : {height: '606px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech2} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='red-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                    User-friendly
                  </h1>
                  <p className='m-b-0'>
                    Eliminate complicated scheduling, interview guide creation, and app downloads. Provide your candidates with a simple, flexible interview experience anytime, anywhere.<br/><br/>
                    With ASTRNT's platform, connect with job seekers effortlessly, whether at home or on the go.
                  </p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='red-back-feature-right for-2 desktop-hide' style={globalGetLanguages == 'en' ? {height: '465.8px'} : {height: '465.8px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-left disable-small-triangle sol-sec2-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging'>
                  <h1 className='m-t-0 m-b-16 tech'>
                  No Need to Limit<br/>Candidates to<br/>Business Hours
                  </h1>
                  <p className='m-b-0'>ASTRNT's Video On-Demand interviews let candidates choose when and where to interview, ensuring a comfortable experience.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech3} />
              </div>
            </div>
          </section>
          <div className='navy-back-feature-left for-1 desktop-hide' style={globalGetLanguages == 'en' ? {height: '500px'} : {height: '500px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech4} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='red-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                    Seamless<br/>ATS Integration
                  </h1>
                  <p className='m-b-0'>Boost the value of your ATS and enhance recruiting efficiency by ensuring your team has the right data at their fingertips. ASTRNT integrates smoothly with your HR tech system, eliminating duplicated tasks and manual inputs, resulting in a faster and more streamlined hiring process.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='red-back-feature-right for-2 desktop-hide' style={globalGetLanguages == 'en' ? {height: '510px'} : {height: '510px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-left disable-small-triangle sol-sec2-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging'>
                  <h1 className='m-t-0 m-b-16 tech'>
                    Ensure Fairness<br/>by Standardizing<br/>Interviews
                  </h1>
                  <p className='m-b-0'>Eliminate reliance on resumes. Structured interviewing allows hiring teams to swiftly create job-specific interviews, replacing inconsistent methods.<br/><br/>Access job-specific interview questions and quickly create on-demand interviews with content vetted by our People Data and Data Innovation team.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech5} />
              </div>
            </div>
          </section>
          <div className='navy-back-feature-left for-1 desktop-hide' style={globalGetLanguages == 'en' ? {height: '608px'} : {height: '608px'}}></div>
        </div>
        <FooterNew />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TechVideo)

// @flow

import React from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reset, change } from 'redux-form'
import { Map } from 'immutable'
import imgAstronautLogo from '../../images/logo-black.svg'
import UserRegisterWizardFormFirstPageNew from './users/UserRegisterWizardFormFirstPageNew'
import UserRegisterWizardFormSecondPageNew from './users/UserRegisterWizardFormSecondPageNew'
import UserRegisterWizardFormThirdPageNew from './users/UserRegisterWizardFormThirdPageNew'
import initializers from '../../dependencies/initializers'
import { usersAddNavigationPage } from '../../actions/users'
import { globalBlockingTransition } from '../../helpers/global'

import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'

import AstrntFavicon from '../../styles/img/favicon.png'
import imgOfficeGirl from '../../styles/img/office-girl.svg'

import imgStudentAdmissions from '../../styles/img/discuss-talent.svg'
import imgCandidateSelection from '../../styles/img/hiring.svg'
import imgInterviewJob from '../../styles/img/interview-job.svg'

type Props = {
	resetForm: Function,
	usersAddNavigationPage: Function,
	usersNavigationData: Map<string, any>
};

type State = {
	unblockTransition: Function,
	cachedUsersNavigationDataPage: string,
	option: String,
	setOption: Function,
	nextPage: Function,
	prevPage: Function
};

class UserRegisterWizardFormContainerNew extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			unblockTransition: () => {},
			cachedUsersNavigationDataPage: '',
			option: 'Candidate Selection',
			setOption: (val) => { this.setState({option: val}) },
			nextPage: () => {},
			prevPage: () => {},
		}
	}

	componentDidMount() {
        const{userTempEmail,history}=this.props
		const titleElement: Object = document.getElementsByTagName('title')
		window.scrollTo(0, 0);
        console.log(userTempEmail.toJS())
        if (!userTempEmail.get('email')) {
            history.push('/no-email-candidate')
        }
		
		
		// titleElement[0].text = 'ASTRNT'
		// window.scrollTo(0, 0);
	    
	    // $('.overlay, .close-button').click(function(event){
	    //     event.preventDefault();
	        
	    //     $('#pop-1').slideUp('fast');
	    //     $('#pop-2').slideUp('fast');
	    //     $('.overlay').fadeOut('fast');
	    // });

		// if('es2t' === this.props.plan) {
	  	//   $('#pop-1').css('display', 'block')
	  	//   $('.overlay').fadeIn('fast');
		//   $('.div-info').css('display', 'none')
		// }

		// if('ngo' === this.props.plan) {
	  	//   $('#how-do-you-hope').css('display', 'list-item')
		// }
	}

	componentWillUnmount() {
		console.log('handled in children page')
		// this.state.unblockTransition()
		const {
			resetForm,
			usersAddNavigationPage
		} = this.props

		resetForm('UserRegisterWizardForm')
		usersAddNavigationPage(1)
	}

	handleLoginLink(e: Object): void {
		e.preventDefault()
			window.location = initializers.recruiter_host+'/auth/login'
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const {
			usersNavigationData,
			usersAddNavigationPage,
			url_location
		} = nextProps

		if (usersNavigationData.get('page') !== prevState.cachedUsersNavigationDataPage) {
			let page: number = usersNavigationData.get('page')

			const nextPage: Function = () => {
				usersAddNavigationPage(page + 1)
			}

			const previousPage: Function = () => {
				usersAddNavigationPage(page - 1)
			}

			switch(page) {
				case 2:
					window.dataLayer.push({
						event: 'registered',
						page: {
						  url: url_location,
						  title: 'registered success'
						}
					  });
					return {
						cachedUsersNavigationDataPage: page,
					}
				default:
					return {
						cachedUsersNavigationDataPage: page,
						prevPage: previousPage,
						nextPage: nextPage,
					}
			}
		}

		return null
	}

	chooseOpt(val) {
	  if( val === 'I’m An Applicant' ) {
		$('.div-info').css('display', 'block')
		$('.box-option-1').removeClass('selected')
		$('.box-option-2').removeClass('selected')
		$('.box-option-3').addClass('selected')
	  } else if (val === 'Recruitment & Assessment') {
		$('.div-info').css('display', 'none')
		$('#pop-1').css('display', 'none')
		$('.box-option-1').removeClass('selected')
		$('.box-option-2').addClass('selected')
		$('.box-option-3').removeClass('selected')
		$('.overlay').fadeOut('fast');
	  } else {
	  	$('.div-info').css('display', 'none')
	  	$('#pop-1').css('display', 'none')
		$('.box-option-1').addClass('selected')
		$('.box-option-2').removeClass('selected')
		$('.box-option-3').removeClass('selected')
		$('.overlay').fadeOut('fast');
	  }

	  $('div.select-plan select').val(val).change();
	  this.props.changeFieldValue('how_you_use_astrnt', val)
	  this.setState({option: val})
	}

	render() {

		return (
			<div id="register">

			<div id="pop-1" className="pop" style={{display: 'none'}}>
			  <div className="pop-head">
			    What best describes your focus?
			    <a className="close-button" href="#"><i className="fa fa-times" /></a>
			  </div>
			  <hr/>
			  <div className="pop-body">
			  	{/*<label>We help in many ways, and want to direct you correctly.</label>*/}
			  	<div className="div-selection horizontal-item--between">
			  		<div className="box-option horizontal-item--center box-option-1" onClick={() => this.chooseOpt('University Admissions')}>
			  			<img src={imgStudentAdmissions} />
			  			<span>University Admissions</span>
			  		</div>
			  		<div className="box-option horizontal-item--center box-option-2" onClick={() => this.chooseOpt('Recruitment & Assessment')}>
			  			<img src={imgCandidateSelection} />
			  			<span>Recruitment & Assessment</span>
			  		</div>
			  		<div className="box-option horizontal-item--center box-option-3" onClick={() => this.chooseOpt('I’m An Applicant')}>
			  			<img src={imgInterviewJob} />
			  			<span>I’m An Applicant</span>
			  		</div>
			  	</div>
			  	<div className={`div-info`}>
			  		<label>Ok, super. That’s great to know.</label>
			  		<p>Applicants should proceed as follows:</p>
			  		<ol>
			  			<li>Check the email sent to you by the organisation you’re interviewing with.</li>
						<li>Insert the provided code to <Link to={`/enter-code`}>ASTRNT</Link>.</li>
						<li>Start the interview.</li>
			  		</ol>
			  		<p>If you need assistance with your interview, please contact our <a href="mailto:support@astrnt.co" target="_blank">support team.</a></p>
			  	</div>
			  </div>
			</div>

			<div id="pop-2" className="pop" style={{display: 'none'}}>
			  <div className="pop-head">
			    If You're An Applicant
			    <a className="close-button" href="#"><i className="fa fa-times" /></a>
			  </div>
			  <hr/>
			  <div className="pop-body">
			  	<div className={`div-info`}>
			  		<p>Applicants should proceed as follows:</p>
			  		<ol>
			  			<li>Check the email sent to you by the organisation you’re interviewing with.</li>
						<li>Insert the provided code to <Link to={`/enter-code`}>ASTRNT</Link>.</li>
						<li>Start the interview.</li>
			  		</ol>
			  		<p>If you need assistance with your interview, please contact our <a href="mailto:support@astrnt.co" target="_blank">support team.</a></p>
			  	</div>
			  </div>
			</div>

			<div className="overlay" style={{display: 'none'}} />

	        <HeaderNewClear />
	        <div className="pad-top desktop-hide" />
	        <section className="sec-reg">
	          <ul className="lay-reg">
	            <li>
	              <div className="desc-log">
	                <span>A faster, smarter way that candidates love.</span>
	                Join hundreds of talent ecosystem leaders digitizing and automating their selection processes and keeping their organisations safe & agile.
	                <ul>
	                  <li><i className="fas fa-check" />No credit card required</li>
	                  <li><i className="fas fa-check" />14 days free trial</li>
	                  <li><i className="fas fa-check" />Unlimited jobs</li>
	                  <li><i className="fas fa-check" />Unlimited users or team members</li>
	                  <li><i className="fas fa-check" />Live chat support</li>
	                </ul>
	                <img src={imgOfficeGirl} />
	              </div>
	            </li>
	            <li>
	              <div className="box-reg">
                    <UserRegisterWizardFormThirdPageNew /> 
	              </div>
	            </li>
	          </ul>
	        </section>
	        <FooterNew />
	      </div>
    )
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		usersNavigationData: state.usersNavigationData,
		plan: (ownProps.location.query ? ownProps.location.query.plan : 'es2t'),
		url_location:ownProps.location,
        userTempEmail:state.userTempEmail,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetForm: (formName) => dispatch(reset(formName)),
		usersAddNavigationPage: (page) => dispatch(usersAddNavigationPage(page)),
		changeFieldValue: (field, value) => dispatch(change('UserRegisterWizardForm', field, value, true, true)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRegisterWizardFormContainerNew)

// @flow

import React,{Fragment} from 'react'
import { Link } from 'react-router-dom'
import Typed from 'react-typed';
import { connect } from 'react-redux'
import $ from "jquery";

import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import FooterNew from '../partials/FooterNew.jsx'

import homeIMG from '../../images/asset-home.png'

import travelokaLogo from '../../images/logo/traveloka.png'
import philipLogo from '../../images/logo/philip.png'
import bcaLogo from '../../images/logo/bca.png'
import mbsbLogo from '../../images/logo/mbsb.png'
import nusLogo from '../../images/logo/nus.png'
import shopeeLogo from '../../images/logo/shopee.png'
import bniLogo from '../../images/logo/bni.png'
import infocommLogo from '../../images/logo/infocomm.png'
import darwinboxLogo from '../../images/logo/darwinbox.png'
import homecreditLogo from '../../images/logo/homecredit.png'

import teamtailorLogo from '../../images/logo/teamtailor.png'
import workableLogo from '../../images/logo/workable.png'
import pulsifiLogo from '../../images/logo/pulsifi.png'
import talentdatalabsLogo from '../../images/logo/talentdatalabs.png'
import podiumLogo from '../../images/logo/podium.png'
import greenhouseLogo from '../../images/logo/greenhouse.png'
import turfreyLogo from '../../images/logo/turfrey.png'

import caseIcon from '../../images/casestudies.png'

import quoteIcon from '../../images/quote.svg'

import imgPlayStore from '../../images/rateAppGoogle.png'
import imgAppStore from '../../images/rateAppApple.png'
import imgPlayStoreMobile from '../../images/rateAppGoogleMobile.png'
import imgAppStoreMobile from '../../images/rateAppAppleMobile.png'

import imgFeature1 from '../../images/feature-1-video.png'
import imgFeature2 from '../../images/feature-2-structured.png'
import imgFeature3 from '../../images/feature-3-assessment.png'
import imgFeature4 from '../../images/feature-4-complete-suite.png'

import imgReview1 from '../../images/review-1.png'
import imgReview2 from '../../images/review-2.png'
import imgReview3 from '../../images/review-3.png'

import imgAssess from '../../images/imgAssess.png'
import imgJP from '../../images/img_jp.png'
import imgSheeren from '../../images/img_sheeren.png'
import imgDevice from '../../images/img_device.png'
import imgDeviceMobile from '../../images/img_device_mobile.png'
import imgReport from '../../images/img_report.png'
import imgCornerRed from '../../images/corner-red.svg'
import imgCornerNavy from '../../images/corner-navy.svg'
import imgWithoutAstrnt from '../../images/without_astrnt.png'
import imgWithAstrnt from '../../images/with_astrnt.png'
import imgBuble01 from '../../images/img_buble_01.png'
import imgBuble02 from '../../images/img_buble_02.png'
import imgStep from '../../images/img_step.png'
import imgStepMobile from '../../images/img_step_mobile.png'

import logos from '../../images/logos.png'
import logo3success from '../../images/logo_3_success.png'
import logoTelkomAkses from '../../images/logo_telkomakses.png'

import personMBSB from '../../images/person_mbsb.png'
import logoMBSB from '../../images/logo_mbsb.png'

import personPintarnya from '../../images/person_pintarnya.png'
import logoPintarnya from '../../images/logo_pintarnya.png'

import personNUS from '../../images/person_nus.png'
import logoNUS from '../../images/logo_nus.png'

import bindAll from 'lodash/bindAll'
import { translate } from '../../helpers/global'
import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

type Props = {};

const dataSlider = [
  {
    img_src:imgReview1,
    logo_src:nusLogo,
    quote:'HOME_PAGE_CUSTOMERS_1',
    address:'Winston Wee, Head, Admissions for MSc Programs, National University of Singapore (NUS) Business School',
  },
  {
    img_src:imgReview2,
    logo_src:mbsbLogo,
    quote:'HOME_PAGE_CUSTOMERS_2',
    address:'Nur Syahada Taufek, Talent Acquisition & Employer Branding Manager at MBSB Bank Berhad',
  },
  {
    img_src:imgReview3,
    logo_src:turfreyLogo,
    quote:'HOME_PAGE_CUSTOMERS_3',
    address:'Alison Donovan, Group Admin Manager, Turfrey Developers New Zealand',
  }
]

const quoteData =  [
  {
    icon:quoteIcon,
    text:'HOME_PAGE_CUSTOMERS_SAY_1',
    label:'Otniel Susanto',
  },
  {
    icon:quoteIcon,
    text:'HOME_PAGE_CUSTOMERS_SAY_2',
    label:'Heidi Smith',
  },
  {
    icon:quoteIcon,
    text:'HOME_PAGE_CUSTOMERS_SAY_3',
    label:'Franklin Mal',
  },
  {
    icon:quoteIcon,
    text:'HOME_PAGE_CUSTOMERS_SAY_4',
    label:'Solihatin Azizah',
  },
  {
    icon:quoteIcon,
    text:'HOME_PAGE_CUSTOMERS_SAY_5',
    label:'Fahmi Hasya',
  },
  {
    icon:quoteIcon,
    text:"HOME_PAGE_CUSTOMERS_SAY_6",
    label:'Eri Montinger',
  }
]


const featureDetail=[
  {
    top:'HOMEPAGE_RENDER_BUTTON_FEATURE_1',
    title:'HOMEPAGE_RENDER_TITLE_FEATURE_1',
    text:'HOMEPAGE_RENDER_TEXT_FEATURE_1',
    img:imgFeature1,
  },
  {
    top:'HOMEPAGE_RENDER_BUTTON_FEATURE_2',
    title:'HOMEPAGE_RENDER_TITLE_FEATURE_2',
    text:'HOMEPAGE_RENDER_TEXT_FEATURE_2',
    img:imgFeature3,
  },
  {
    top:'HOMEPAGE_RENDER_BUTTON_FEATURE_3',
    title:'HOMEPAGE_RENDER_TITLE_FEATURE_3',
    text:'HOMEPAGE_RENDER_TEXT_FEATURE_3',
    img:imgFeature2,
  },
  {
    top:'HOMEPAGE_RENDER_BUTTON_FEATURE_4',
    title:'HOMEPAGE_RENDER_TITLE_FEATURE_4',
    text:'HOMEPAGE_RENDER_TEXT_FEATURE_4',
    img:imgFeature4,
  },
]

class HomePageNew extends React.Component<Props> {

	constructor(props) {
		super(props)
    
    this.state={
      sliderStateIndex:0,
      quoteSliderStateIndex:0,
      buttonFeatureIndex:0,
      sliderIndex:1,
      sliderBigIndex:1,
    }

		bindAll(this, [
			'handleRegisterLink',
      'renderSlider',
      'moveSlide',
      'moveSlide1',
      'moveSlideBig',
      'renderSliderQuote',
      'renderFeature',
      'renderButtonFeature'
		])
	}
  moveSlide1(n)
  {
    const{sliderIndex} = this.state
    if (sliderIndex+n > 3) {
      this.setState({sliderIndex:1})
    }else if (sliderIndex+n < 1) {
      this.setState({sliderIndex:3})
    }else{
      this.setState({sliderIndex:sliderIndex+n})
    }
  }
  moveSlideBig(n)
  {
    const{sliderBigIndex} = this.state
    if (sliderBigIndex+n > 2) {
      this.setState({sliderBigIndex:1})
    }else if (sliderBigIndex+n < 1) {
      this.setState({sliderBigIndex:2})
    }else{
      this.setState({sliderBigIndex:sliderBigIndex+n})
    }
  }
  renderButtonFeature(){
    const{globalGetLanguages} = this.props
    const {buttonFeatureIndex} = this.state
    

    return (
      <Fragment>
                  <div className="div-button-feature">
                      <button onClick={()=>{
                        this.setState({
                          buttonFeatureIndex:0
                        })
                      }} className={`button-feature ${buttonFeatureIndex == 0 ? 'active' : ''} `}>{translate(globalGetLanguages,'HOMEPAGE_RENDER_BUTTON_FEATURE_1')}</button>
                      <button 
                       onClick={()=>{
                        this.setState({
                          buttonFeatureIndex:1
                        })
                      }} className={`button-feature ${buttonFeatureIndex == 1 ? 'active' : ''} `}>{translate(globalGetLanguages,'HOMEPAGE_RENDER_BUTTON_FEATURE_2')}</button>
                    </div>
                    <div className="div-button-feature">
                      <button
                       onClick={()=>{
                        this.setState({
                          buttonFeatureIndex:2
                        })
                      }}
                      className={`button-feature ${buttonFeatureIndex == 2 ? 'active' : ''} `}>{translate(globalGetLanguages,'HOMEPAGE_RENDER_BUTTON_FEATURE_3')}</button>
                      <button
                       onClick={()=>{
                        this.setState({
                          buttonFeatureIndex:3
                        })
                      }}
                      className={`button-feature ${buttonFeatureIndex == 3 ? 'active' : ''} `}>{translate(globalGetLanguages,'HOMEPAGE_RENDER_BUTTON_FEATURE_4')}</button>
                    </div>
      </Fragment>
    )
  }

  renderFeature(){
    const{globalGetLanguages} = this.props
    const {buttonFeatureIndex} = this.state

    return(
      <Fragment>
        <label>
        {translate(globalGetLanguages,featureDetail[buttonFeatureIndex].top)}</label>
        {translate(globalGetLanguages,featureDetail[buttonFeatureIndex].title)}
        <div className="mobile-hide text-center"><img src={featureDetail[buttonFeatureIndex].img} /></div>
        <span>{translate(globalGetLanguages,featureDetail[buttonFeatureIndex].text)}</span>
      </Fragment>
    )
  }
	componentDidMount() {
    // console.log(`ini os apa : ${getOSInfo().name}`)
    // console.log(`ini versi  : ${getOSInfo().version}`)
		window.scrollTo(0, 0);

    // $('.ban-text a.vid-msg').click(function(event){
    //     event.preventDefault();
        
    //     $('.pop').slideDown('fast');
    //     $('.overlay').fadeIn('fast');
    //     var video = document.getElementById('video-control')
    //     video.play();
        
    // });
    
    // $('.overlay, .pop a').click(function(event){
    //     event.preventDefault();
        
    //     $('.pop').slideUp('fast');
    //     $('.overlay').fadeOut('fast');
    //     var video = document.getElementById('video-control')
    //     video.pause();
    //     video.currentTime = 0;
    // });
    $('.with-container img:gt(0)').hide();
    setInterval(function () {
        $('.with-container :first-child').fadeOut().next('img').fadeIn().end().appendTo('.with-container');
    }, 3000);
	}

	handleRegisterLink(e: Object): void {
		e.preventDefault()

		const{
			history
		}=this.props
		
		history.push('/register')

		
	}

  moveSlide(e,params,quote=null){
    const{sliderStateIndex,quoteSliderStateIndex} = this.state
    if (quote) {
      if (params) {
        this.setState({quoteSliderStateIndex:quoteSliderStateIndex+1})
      }else{
        this.setState({quoteSliderStateIndex:quoteSliderStateIndex-1})
      }
    }else{
      if (params) {
        this.setState({sliderStateIndex:sliderStateIndex+1})
      }else{
        this.setState({sliderStateIndex:sliderStateIndex-1})
      }
    }
   
  }

  renderSliderQuote(){
    const{globalGetLanguages}=this.props
    const{quoteSliderStateIndex}=this.state
    let element = []
    let currentSize = quoteData.length
    quoteData.map((obj,index)=>{
      element.push(
        <div key={`quote-${index}`} className={quoteSliderStateIndex === index  ? "slider-item-container active" : "slider-item-container absolute "}>
          <img src={obj.icon} />
          <div className="flex-container horizontal-item--between">
            <div className="app-text-review">
              <div className="flex-container flex-row horizontal-item--between">
                <button
                 onClick={(e)=>{this.moveSlide(e,false,true)}} 
                 className={`${quoteSliderStateIndex == 0? 'disabled':''}`}
                 disabled={quoteSliderStateIndex == 0? true:false}
                ><i 
               
                className="fas fa-chevron-left"></i>
                </button>
                <div key={`quote-${index}`} className={quoteSliderStateIndex === index  ? "slider-item active-anim" : "slider-item absolute "}>
                  <p>{translate(globalGetLanguages,obj.text)}</p>
                </div>
                <button
                 onClick={(e)=>{this.moveSlide(e,true,true)}}
                 className={`${quoteSliderStateIndex == (currentSize-1)? 'disabled':''}`}
                 disabled={quoteSliderStateIndex == (currentSize-1)? true:false}
                ><i
               
                className="fas fa-chevron-right"></i>
                </button>
              </div>
              <div key={`quote-${index}`} className={quoteSliderStateIndex === index  ? "slider-item active-anim" : "slider-item absolute "}>
                <label>{obj.label}</label>
              </div>
              <div className="bottom-dots">
               {this.renderButtonDotSmall(true)}
              </div>
            </div>
            <div className="store-container desktop-hide">
              <a className="a-transparent" href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer"><img className="img-appstore" src={imgAppStore} /></a>
              <a className="a-transparent" href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer"><img src={imgPlayStore} /></a>
            </div>
            <div className="store-container mobile-hide">
              <div className="horizontal-item--around">
                <a className="a-transparent" href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer"><img src={imgAppStoreMobile} /></a>
                <a className="a-transparent" href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer"><img src={imgPlayStoreMobile} /></a>
              </div>
            </div>
          </div>
        </div>
      )
    })
    return element
  }

  renderSlider(){
    const{globalGetLanguages} = this.props
    const{sliderStateIndex} = this.state
    let element= []
    let currentSize = dataSlider.length
    dataSlider.map((obj,index)=>{
        element.push(
          <div className={sliderStateIndex === index  ? "company-review flex-container active-anim" : "company-review flex-container absolute"}>
              <div className="desktop-hide">
                <div key={`quote-${index}`} className={sliderStateIndex === index  ? "slider-item active-anim" : "slider-item absolute "}>
                  <img src={obj.img_src} />
                </div>
              </div>
              <div className="review-text flex-container flex-grow">
                <div>
                  <div className="top-review-text flex-between vertical-item--top">
                    <div key={`quote-${index}`} className={sliderStateIndex === index  ? "slider-item active-anim" : "slider-item absolute "}>
                      <img src={obj.logo_src} />
                    </div>
                    <div className="arrow-slider flex-container no-transition">
                      <div>
                        <button 
                        onClick={(e)=>{this.moveSlide(e,false)}} 
                        className={`${sliderStateIndex == 0? 'disabled':''}`}
                        disabled={sliderStateIndex == 0? true:false}
                        >
                        <i className="fas fa-arrow-left"></i>
                        </button>
                      </div>
                      <div >
                        <button 
                        onClick={(e)=>{this.moveSlide(e,true)}}
                        className={`${sliderStateIndex == (currentSize-1)? 'disabled':''}`}
                        disabled={sliderStateIndex == (currentSize-1)? true:false}
                        >
                        <i className="fas fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="desktop-hide middle-review-text">
                    <img src={quoteIcon} />
                    <div key={`quote-${index}`} className={sliderStateIndex === index  ? "slider-item active-anim" : "slider-item absolute "}>
                      <label dangerouslySetInnerHTML={{__html: translate(globalGetLanguages,obj.quote)}}></label>
                      <p>{obj.address}</p>
                    </div>
                  </div>
                  <div className="mobile-hide m-t-8">
                    <div className="flex-between vertical-item--top">
                      <div className="image-review-container-mobile">
                        <div key={`quote-${index}`} className={sliderStateIndex === index  ? "slider-item active-anim" : "slider-item absolute "}>
                          <img src={obj.img_src} />
                        </div>
                      </div>
                      <div className="middle-review-text">
                        <img src={quoteIcon} />
                        <div key={`quote-${index}`} className={sliderStateIndex === index  ? "slider-item active-anim" : "slider-item absolute "}>
                          <label dangerouslySetInnerHTML={{__html: translate(globalGetLanguages,obj.quote)}}></label>
                          <p>{obj.address}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-dots no-transition desktop-hide">
                  {this.renderButtonDot()}
                </div>
              </div>
              <div className="bottom-dots no-transition mobile-hide">
                {this.renderButtonDot()}
              </div>
          </div>
        )
      
      
    })

    return element
  }

  renderButtonDot(quote=null){
    const{sliderStateIndex,quoteSliderStateIndex} = this.state
    let buttonSlider = []
    if (quote) {
      dataSlider.map((obj,index)=>{
        if (quoteSliderStateIndex == index) {
          buttonSlider.push(
          <div ></div> 
  
            )
        }else{
          buttonSlider.push(
            <div
            onClick={()=>{
              this.setState({quoteSliderStateIndex:index})
            }}
             className="disabled"></div>
            )
        }
       
      })
    }else{
      dataSlider.map((obj,index)=>{
        if (sliderStateIndex == index) {
          buttonSlider.push(
          <button ></button> 
  
            )
        }else{
          buttonSlider.push(
            <button
            onClick={()=>{
              this.setState({sliderStateIndex:index})
            }}
             className="disabled"></button>
            )
        }
       
      })
    }
    

    return buttonSlider
  }

  renderButtonDotSmall(quote=null){
    const{sliderStateIndex,quoteSliderStateIndex} = this.state
    let buttonSliderSmall = []
    if (quote) {
      quoteData.map((obj,index)=>{
        if (quoteSliderStateIndex == index) {
          buttonSliderSmall.push(
          <div ></div> 
  
            )
        }else{
          buttonSliderSmall.push(
            <div
            onClick={()=>{
              this.setState({quoteSliderStateIndex:index})
            }}
             className="disabled"></div>
            )
        }
       
      })
    }else{
      quoteData.map((obj,index)=>{
        if (sliderStateIndex == index) {
          buttonSliderSmall.push(
          <button ></button> 
  
            )
        }else{
          buttonSliderSmall.push(
            <button
            onClick={()=>{
              this.setState({sliderStateIndex:index})
            }}
             className="disabled"></button>
            )
        }
       
      })
    }
    

    return buttonSliderSmall
  }


  render() {
    const{globalGetLanguages,user} = this.props
    const{buttonFeatureIndex,sliderIndex,sliderBigIndex} = this.state
    const renderHTML = (rawHTML: string) => React.createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } });
    console.log(globalGetLanguages)
    const typedcontent = (globalGetLanguages == 'id') ? ['Lulusan Baru', 'Profesional', 'Universitas', 'Karyawan &amp; Buruh', 'Lembaga Penilaian', 'Platform Talenta'] : ['Graduate Hiring', 'Professionals', 'Universities', 'Blue Collars', 'Assessments', 'Talent Platforms'] ;
    return (
			<div id="homepage">

        <div className="overlay" style={{display: 'none'}} />

        <HeaderNewClear isOnStatusLogo="is-on" />
        {/*        <div class="pad-top"></div>*/}
        <div className="banner home">
          <section>
            <div className="ban-text">
              <div className="big-text">{translate(globalGetLanguages,'HOMEPAGE_BANNER_TITLE')}
              {/* <br />
              <Typed
                strings={typedcontent}
                typeSpeed={100}
                backSpeed={20}
                showCursor={false}
                loop >
                    <em />
                </Typed> */}
                </div>
                {renderHTML(translate(globalGetLanguages,'HOMEPAGE_BANNER_TEXT'))}
              <div className={`ban-link`}>
                <Link to={{pathname: `/register`, query: { plan: 'es2t' }}} className="try">{translate(globalGetLanguages,'SIGNUP_TODAY')}</Link>
                <Link to='/request-demo' className="request-demo-logged">{translate(globalGetLanguages,'HOMEPAGE_BUTTON_REQUEST_DEMO')}</Link>
              </div>
              <div className={`ban-link hide`}>
                <Link to='/request-demo' className='request-demo-logged'>{translate(globalGetLanguages,'HOMEPAGE_BUTTON_REQUEST_DEMO')}</Link>
              </div>
              {/* <span className={`span-cc desktop-hide`}>{translate(globalGetLanguages,'HOMEPAGE_LABEL_NO_CREDIT_CARD')}</span> */}
              <a href="#" className="vid-msg">
                <img src={homeIMG} />
              </a>
            </div>
          </section>
          {/*<div className="ban-img" />*/}
        </div>
        <div className="section-red triangle-left" style={{position: 'relative', overflow: 'hidden'}}>
          <section className='flex-container'>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <img className='img-100' src={imgJP} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='sec-desc white'>
                  <h1 className='m-t-0 m-b-24'>
                    {renderHTML(translate(globalGetLanguages,'EXPERT_SOLUTION'))}
                  </h1>
                  <p className='m-b-24'>{translate(globalGetLanguages,'OUR_EXPERT_TEAM')}</p>
                  <div className='desc-check-yellow'>
                    <span><i className='fas fa-check'></i>{translate(globalGetLanguages,'HIRING_TRANSFORMED')}</span>
                    <span><i className='fas fa-check'></i>{translate(globalGetLanguages,'TALENT_MANAGEMENT_CERT')}</span>
                    <span><i className='fas fa-check'></i>{translate(globalGetLanguages,'UNIV_ADMS_MODERN')}</span>
                    <span><i className='fas fa-check'></i>{translate(globalGetLanguages,'UNIV_CAREER_EMPOWERED')}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="section-white relative">
          <section className='flex-container waterfall-red'>
            <div className='flex-item-left relative'>
              <div className='vertical-item--center'>
                <div className='sec-desc navy'>
                  <h1 className='m-t-0 m-b-12'>
                    {renderHTML(translate(globalGetLanguages,'HOLISTIC_COMP_SCIENCE'))}
                  </h1>
                  <p className={`${globalGetLanguages == 'id' ? 'm-b-20' : 'm-b-24'}`}>{translate(globalGetLanguages,'USE_THE_RIGHT_TOOLS')}</p>
                  <div className='list-navy'>
                    <ul>
                      <li>{translate(globalGetLanguages,'NAVY_LIST_1')}</li>
                      <li>{translate(globalGetLanguages,'NAVY_LIST_2')}</li>
                      <li>{translate(globalGetLanguages,'NAVY_LIST_3')}</li>
                      <li>{translate(globalGetLanguages,'NAVY_LIST_4')}</li>
                      <li>{translate(globalGetLanguages,'NAVY_LIST_5')}</li>
                      <li>{translate(globalGetLanguages,'NAVY_LIST_6')}</li>
                      <li>{translate(globalGetLanguages,'NAVY_LIST_7')}</li>
                      <li>{translate(globalGetLanguages,'NAVY_LIST_8')}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <img className="corner-arch" src={imgCornerRed} />
            </div>
            <div className='flex-item-right flex-center align-items-end'>
                <div>
                  <img className='img-100' src={imgReport} />
                </div>
                <p className="under-img-report">{translate(globalGetLanguages,'OUR_ASSESS_PRODUCTS')}</p>
            </div>
          </section>
          <div className='red-back-feature desktop-hide'></div>
        </div>
        <div className="section-white single-triangle triangle-right" style={{position: 'relative'}}>
          <section className='flex-container p-b-0 container-sheeren'>
            <div className='flex-item-left'>
              <div className='flex-center align-items-end'>
                <img className='img-100' src={imgSheeren} />
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center'>
                <div className='dix-text-sheeren'>
                  <p>{translate(globalGetLanguages,'SHEEREN_TEXT')}</p>
                  <h2>Sheeren Yolanda</h2>
                  <p>{renderHTML(translate(globalGetLanguages,'SHEEREN_POSITION'))}</p>
                </div>
            </div>
          </section>
        </div>
        <div id='assess' className="section-darkblue" style={{position: 'relative', zIndex: '1'}}>
          <section className="p-t-80 p-b-80">
            <div className="text-center sec-device">
              <h1>{translate(globalGetLanguages,'CUST_END_TO_END_EXP')}</h1>
              <p>{translate(globalGetLanguages,'EASY_APPLY_WITH_VERIFY')}</p>
              <img className='img-100 desktop-hide img-responsive' src={imgDevice} />
              <img className='img-100 mobile-hide img-responsive' src={imgDeviceMobile} />
            </div>
          </section>
        </div>
        <div className="section-white relative double-triangle triangle-right">
          <section className='flex-container waterfall-navy'>
            <div className='flex-item-left relative'>
              <div className='horizontal-item--center with-container'>
                <img id='without' className='img-100' src={imgWithoutAstrnt} />
                <img id='with' className='img-100' src={imgWithAstrnt} />
              </div>
              <img className="corner-arch" src={imgCornerNavy} />
            </div>
            <div className='flex-item-right horizontal-item--center'>
                <div className="navy-container-text">
                  <h1>{translate(globalGetLanguages,'REAL_BUSINESS_IMPACT')}</h1>
                  <p>{translate(globalGetLanguages,'OPTIMISE_TIME_METRICS')}</p>
                </div>
            </div>
          </section>
          <div className='navy-back-feature desktop-hide'></div>
        </div>
        <div className={sliderBigIndex == 1  ? `section-red container-fireworks triangle-right active-slider fadeIn` : `section-red container-fireworks triangle-right hide`} style={{position: 'relative',overflow: 'hidden'}}>
          <section className='flex-container flip-flex-mobile'>
            <div className='mobile-flex-hide btn-container-mobile'>
              <button 
                className={`btn-slider-big white left`}
                onClick={(e)=>{this.moveSlideBig(-1)}}
                >
                <i className="fas fa-arrow-left"></i>
              </button>
              <button 
                className={`btn-slider-big white right`}
                onClick={(e)=>{this.moveSlideBig(1)}}
                >
                <i className="fas fa-arrow-right"></i>
              </button>
            </div>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging'>
                  <h1 className='m-t-0 m-b-16'>
                    {translate(globalGetLanguages,'DELIVERING_SUCCESS')}
                  </h1>
                  <span className='m-b-16'>{translate(globalGetLanguages,'NEXT_GEN_ASSESS_CENTER')}</span>
                  <p className='m-b-0'>{translate(globalGetLanguages,'SAMPOERNA_SAW')}</p>
                  <img className='img-100' src={logo3success} />
                </div>
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center relative'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgBuble01} />
              </div>
              <button 
                className={`btn-slider-big white right desktop-hide`}
                onClick={(e)=>{this.moveSlideBig(1)}}
                >
                <i className="fas fa-arrow-right"></i>
              </button>
            </div>
          </section>
          <div className='navy-back-feature-left desktop-hide' style={globalGetLanguages == 'id' ? {height: '585px'} : {}}></div>
        </div>
        <div className={sliderBigIndex == 2 ? `section-red container-fireworks triangle-left active-slider fadeIn` : `section-red container-fireworks triangle-left hide`} style={{position: 'relative',overflow: 'hidden'}}>
          <section className='flex-container'>
            <div className='flex-item-left horizontal-item--center relative'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgBuble02} />
              </div>
              <button 
                className={`btn-slider-big white left desktop-hide`}
                onClick={(e)=>{this.moveSlideBig(-1)}}
                >
                <i className="fas fa-arrow-left"></i>
              </button>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                    {translate(globalGetLanguages,'TALENT_MANAGE_NEXTGEN_ASSESS')}
                  </h1>
                  <p className='m-b-0'>{translate(globalGetLanguages,'TELKOM_AKSES_AIMED')}</p>
                  <img className='img-100' src={logoTelkomAkses} />
                </div>
              </div>
            </div>
            <div className='mobile-hide w-100'>
              <div className='btn-container-mobile'>
                <button 
                  className={`btn-slider-big white left`}
                  onClick={(e)=>{this.moveSlideBig(-1)}}
                  >
                  <i className="fas fa-arrow-left"></i>
                </button>
                <button 
                  className={`btn-slider-big white right`}
                  onClick={(e)=>{this.moveSlideBig(1)}}
                  >
                  <i className="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </section>
          <div className='navy-back-feature-right telkom desktop-hide' style={globalGetLanguages == 'id' ? {height: '606px'} : {}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-left" style={{overflow: 'hidden'}}>
          <section>
            <button 
              className={`btn-slider-big navy left desktop-hide`}
              onClick={(e)=>{this.moveSlide1(-1)}}
              >
              <i className="fas fa-arrow-left"></i>
            </button>
            <div className={sliderIndex == 1  ? `text-center review-slider active-slider fadeIn` : `text-center review-slider `}>
              <p>"{translate(globalGetLanguages,'NUR_REVIEW')}"</p>
              <div className='horizontal-item--between source-div'>
                <div className='vertical-item--center'>
                  <img className='person-img' src={personMBSB} />
                  <div className='flex-column'>
                    <h5>Nur Syahada Taufek</h5>
                    <h6>{renderHTML(translate(globalGetLanguages,'NUR_POS'))}</h6>
                  </div>
                </div>
                <div className='vertical-item--center'>
                  <img src={logoMBSB} />
                </div>
              </div>
            </div>
            <div className={sliderIndex == 2  ? `text-center review-slider active-slider fadeIn` : `text-center review-slider`}>
              <p>"{translate(globalGetLanguages,'NELLY_REVIEW')}"</p>
              <div className='horizontal-item--between source-div'>
                <div className='vertical-item--center'>
                  <img className='person-img' src={personPintarnya} />
                  <div className='flex-column'>
                    <h5>Nelly Nurmalasari</h5>
                    <h6>Co-Founder of Pintarnya</h6>
                  </div>
                </div>
                <div className='vertical-item--center'>
                  <img src={logoPintarnya} />
                </div>
              </div>
            </div>
            <div className={sliderIndex == 3  ? `text-center review-slider active-slider fadeIn` : `text-center review-slider`}>
              <p>"{translate(globalGetLanguages,'WINSTON_REVIEW')}"</p>
              <div className='horizontal-item--between source-div'>
                <div className='vertical-item--center'>
                  <img className='person-img' src={personNUS} />
                  <div className='flex-column'>
                    <h5>Winston Wee</h5>
                    <h6>{renderHTML(translate(globalGetLanguages,'WINSTON_POS'))}</h6>
                  </div>
                </div>
                <div className='vertical-item--center'>
                  <img src={logoNUS} />
                </div>
              </div>
            </div>
            <button 
              className={`btn-slider-big navy right desktop-hide`}
              onClick={(e)=>{this.moveSlide1(1)}}
              >
              <i className="fas fa-arrow-right"></i>
            </button>
            <div className='mobile-hide'>
              <div className='btn-container-mobile'>
                <button 
                  className={`btn-slider-big navy left`}
                  onClick={(e)=>{this.moveSlide1(-1)}}
                  >
                  <i className="fas fa-arrow-left"></i>
                </button>
                <button 
                  className={`btn-slider-big navy right`}
                  onClick={(e)=>{this.moveSlide1(1)}}
                  >
                  <i className="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </section>
        </div>
        <div className="section-darkblue triangle-right" style={{position: 'relative'}}>
          <section className='flex-container p-t-100 p-b-100'>
            <div className='flex-item-left relative flex-center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={logos} />
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center'>
                <div className="navy-container-text">
                  <h1>{translate(globalGetLanguages,'ELEVATE_YOUR_PLATFORM')}</h1>
                  <p>{translate(globalGetLanguages,'WE_PARTNER_WITH_ATS')}</p>
                  <Link to={{pathname: `/register`, query: { plan: 'es2t' }}} className="btn-primary m-t-32">{translate(globalGetLanguages,'EXPLORE_OPPORTUNITIES')}</Link>
                </div>
            </div>
          </section>
        </div>
        <div className="section-white single-triangle triangle-right" style={{position: 'relative'}}>
          <section className='p-t-100 p-b-100'>
            <div className='step-container'>
              <div>
                  <h1>{renderHTML(translate(globalGetLanguages,'UP_RUNNING_4_STEPS'))}</h1>
                  <p>{translate(globalGetLanguages,'START_FREE_ASTRNT_ACCOUNT_TODAY')}</p>
                  <Link to={{pathname: `/plans`, query: { plan: 'es2t' }}} className="btn-primary">{translate(globalGetLanguages,'SEE_OUR_PLANS')}</Link>
              </div>
              <div className='horizontal-item--center'>
                <img className='img-100 desktop-hide img-responsive' src={imgStep} />
                <img className='img-100 mobile-hide img-responsive' src={imgStepMobile} />
              </div>
            </div>
          </section>
        </div>
        <div className="sec-white hide" style={{position: 'relative'}}>
          <section className="section-review">
            <div className="top-review">
              <label>{translate(globalGetLanguages,'HOMEPAGE_CARD_CUSTOMERS_SAY')}</label>
              <p>{translate(globalGetLanguages,'HOMEPAGE_CARD_CUSTOMERS_ACCELERATE')}</p>
            </div>
            <div className='slider-container'>
              {this.renderSlider()}
            </div>
            <div className="candidate-hr">
              <label>
               {translate(globalGetLanguages,'HOMEPAGE_CARD_SATISFIED_CANDIDATE')}
              </label><hr />
            </div>
            <div className="app-review">
              <div className='slider-container'>
                {this.renderSliderQuote()}
              </div>
            </div>
          </section>
        </div>
        {/*<BlogListNew />*/}
        <FooterNew />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return{ 
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
  }
  
}

const mapDispatchToProps = (dispatch) => {
  return {
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageNew)

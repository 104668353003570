// @flow
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
// import '../../styles/css/astronaut.about-1.0.css'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import window1 from '../../images/window1.png'
import manfredNigel from '../../images/manfred-nigel.png'
import teamMember1 from '../../images/team-member1.png'
import teamMember2 from '../../images/team-member2.png'
import teamMember3 from '../../images/team-member3.png'
import teamMember4 from '../../images/team-member4.png'
import teamMember5 from '../../images/team-member5.png'
import nigel from '../../images/Nigel.png'
import manfred from '../../images/Manfred.png'
import chandra from '../../images/Chandra.png'
import sheeren from '../../images/sheeren.png'
import galih from '../../images/Galih.png'
import devi from '../../images/Devi.png'
import vinsen from '../../images/vinsen.png'
import raya from '../../images/raya.png'
import nadya from '../../images/nadya.png'
import about1 from '../../images/about1.png'
import _axios from '../../dependencies/_axios'

import { connect } from 'react-redux'
import { translate } from '../../helpers/global'

type Props = {};

type State = {
	jobs: Array<Object>
};

class TeamPageNew extends React.Component<Props, State> {
  constructor() {
		super()

		this.state = {
			jobs: []
		}
	}

	componentDidMount() {

        _axios({
			url: `/astro-jobs`,
			timeout: 200000,
			method: 'get'
		})
		.then((response) => {
			this.setState({jobs: response.data})
		})
		.catch((error) => {
			console.error(error)
		})

		window.scrollTo(0, 0)
	}


  render() {
		const { jobs } = this.state
		const { globalGetLanguages, user } = this.props
		const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
		let cols = ''
		let videoNigel

		if (jobs.length) {
			if (jobs.length === 1) {
				cols = 'col-sm-12'
			} else if ( jobs.length === 2 ) {
				cols = 'col-sm-6'
			} else {
				cols = 'col-sm-4'
			}

			videoNigel =	jobs
				.map((item, index) => (
				<Fragment>
                    <source src={item.job_ads_video} type="video/mp4" />
                    Your browser does not support the video tag.
          		</Fragment>
				))
		}

    return (
		<div id="about">
	        <HeaderNewClear />
			{/* <section style={{paddingBottom: '180px'}}></section> */}
			<section className="pad-plus">
	          <div className="tit-about">
				<h1>
					{translate(globalGetLanguages, 'ABOUT_CARD_1_TITLE')}
				</h1>
	            <span>
				  {translate(globalGetLanguages, 'ABOUT_CARD_1_TEXT')}
				</span>
	          </div>
			  <img src={window1} alt="" />
	        </section>
			<div className="sec-white hide">
			  <section className="sec-history">
				<div className='history text-justify'>
					<div className='row'>
						<div className='col-md-6 col-xs-12'>
							<h1>{translate(globalGetLanguages, 'ABOUT_CARD_2_TITLE')}</h1>
							<p>
							{translate(globalGetLanguages, 'ABOUT_CARD_2_TEXT')}
							</p>
						</div>
						<div className='col-md-6 col-xs-12'><img src={manfredNigel} /></div>
					</div>
				</div>
				
			  </section>
			</div>
	        <div className="sec-team">
				<section className="pad-plus">
					<div className='team-gallery hide'>
						<div className="title-meet-people">
							<h1>
								{translate(globalGetLanguages, 'ABOUT_CARD_3_TITLE')}
							</h1>
							<span>
								{renderHTML(translate(globalGetLanguages, 'ABOUT_CARD_3_TEXT'))}
							</span>
						</div>
						<div className='gallery'>
							<div className='main-grid'>
								<div className='left-grid'>
									<div className='image-1'>
										<img src={teamMember2} alt="" />
									</div>
								</div>
								<div className='right-grid'>
									<div className='item-grid'>
										<div className='image-2'>
											<img src={teamMember4} alt="" />
										</div>
										<div className='image-3'>
											<img src={teamMember3} alt="" />
										</div>
									</div>
									<div className='item-grid'>
										<div className='image-4'>
											<img  src={teamMember5} alt="" />
										</div>
										<div className='image-5'>
											<img src={teamMember1} alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					<div className='leadership'>
						<div className="title-leadership">{translate(globalGetLanguages, 'ABOUT_CARD_4_TITLE')}</div>
						<div className="row">
							<div className='col-md-4 col-md-offset-2 col-xs-12 col-sm-6  col-sm-offset-3'>
								<div className='team-panel'>
									<div className='team-image bg-1'>
										<img className="img img-responsive" src={nigel} alt="nigel" />
									</div>
									<div className="team-detail">
										<h2 className='team-name'>Nigel Hembrow</h2>
										<span className='team-position'>{`CEO & CO-FOUNDER`}</span>
										<span className='team-description'>{translate(globalGetLanguages, 'ABOUT_CARD_4_CEO_DESC')}</span>
									</div>
								</div>
							</div>
							<div className='col-md-4 col-md-offset-0 col-xs-12 col-sm-6  col-sm-offset-3'>
								<div className='team-panel'>
									<div className='team-image bg-2'>
										<img src={sheeren} className="img img-responsive" alt="sheeren" />
									</div>
									<div className="team-detail">
										<h2 className='team-name'>Sheeren Yolanda</h2>
										<span className='team-position'>{`PEOPLE DATA & DECISION INNOVATION TEAM LEAD`}</span>
										<span className='team-description'>{translate(globalGetLanguages, 'ABOUT_CARD_4_SHEEREN_DESC')}</span>
									</div>
								</div>
							</div>
							<div className='col-md-4 col-md-offset-2 col-xs-12 col-sm-6  col-sm-offset-3'>
								<div className='team-panel'>
									<div className='team-image bg-5'>
										<img className="img img-responsive" src={raya} alt="raya" />
									</div>
									<div className="team-detail">
										<h2 className='team-name'>Diptraya P. Ratulangi</h2>
										<span className='team-position'>{`COMMERCIAL LEAD`}</span>
										<span className='team-description'>{translate(globalGetLanguages, 'ABOUT_CARD_4_RAYA_DESC')}</span>
									</div>
								</div>
							</div>
							<div className='col-md-4 col-md-offset-0 col-xs-12 col-sm-6  col-sm-offset-3'>
								<div className='team-panel'>
									<div className='team-image bg-6'>
										<img className="img img-responsive" src={nadya} alt="nadya" />
									</div>
									<div className="team-detail">
										<h2 className='team-name'>Nadya Rompies</h2>
										<span className='team-position'>{`SENIOR MANAGER PRODUCT MARKETING & strategy`}</span>
										<span className='team-description'>{renderHTML(translate(globalGetLanguages, 'ABOUT_CARD_4_NADYA_DESC'))}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
	        </div>
			<div className="sec-white about-people">
				<section className="sec-con">
					<div className="tit-about-people">
						<h1>
						{translate(globalGetLanguages, 'ABOUT_CARD_5_TITLE')}
						</h1>
						<h3>
						{translate(globalGetLanguages, 'ABOUT_CARD_5_SUBTITLE')}
						</h3>
						<p>
						{renderHTML(translate(globalGetLanguages, 'ABOUT_CARD_5_TEXT'))}
						</p>
						<p>
						{translate(globalGetLanguages, 'ABOUT_CARD_5_TEXT2')}
						</p>
					</div>
					<div className='talent-pool'>
						<h1>{translate(globalGetLanguages, 'ABOUT_CARD_5_CHILD_TITLE')}</h1>
						<p>
							{renderHTML(translate(globalGetLanguages, 'ABOUT_CARD_5_CHILD_TEXT'))}
						</p>
						<a href="https://bit.ly/astrnt_talentpool" className='btn btn-lg btn-default' target="_blank">{translate(globalGetLanguages, 'ABOUT_CARD_5_CHILD_BUTTON')}</a>
					</div>
				</section>
			</div>
			<div className='request-demo container'>
				<section>
					<div className='row'>
						<div className='col-md-7 col-xs-12'>
							<div><h1>{translate(globalGetLanguages,'HOMEPAGE_READY_TO_CREATE')}</h1></div>
							<div><p>{translate(globalGetLanguages,'HOMEPAGE_WE_HELP')}</p></div>
							<div>
								<div>
									<div className='col-md-4 col-xs-12'>
										<Link to={{pathname: `/register`, query: { plan: 'es2t' }}} className='btn-md  btn btn-danger btn-start-trial button-center-xs'>{translate(globalGetLanguages,'HOMEPAGE_BUTTON_START_FREE_TRIAL')}</Link>
										<span className='hidden-xs hidden-sm'>{translate(globalGetLanguages,'HOMEPAGE_LABEL_NO_CREDIT_CARD')}</span>
										<span className='visible-xs visible-sm text-center'>{translate(globalGetLanguages,'HOMEPAGE_LABEL_NO_CREDIT_CARD')}</span>
									</div>
									<div className='col-md-4 col-xs-12 button-center-xs'>
										<Link to='/request-demo' className='btn-md btn btn-request-demo button-center-xs'>{translate(globalGetLanguages,'HOMEPAGE_BUTTON_REQUEST_DEMO')}</Link>
									</div>
								</div>
								<div className={`hide`}>
									<Link to='/request-demo' className='request-demo-logged'>{translate(globalGetLanguages,'HOMEPAGE_BUTTON_REQUEST_DEMO')}</Link>
								</div>
							</div>
						</div>
						<div className='col-md-5  col-xs-12'>
							<img className='img-request-demo' src={about1}/>
						</div>
					</div>
				</section>
			</div>
	        <SupportListNew />
	        <FooterNew />
	      </div>
		)
  }
}

const mapStateToProps = (state) => {
    return{ 
      globalGetLanguages:state.globalGetLanguages,
	  user: state.user
    }
    
  }

export default connect(mapStateToProps, null )(TeamPageNew)
